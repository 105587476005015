<template>
  <confirm-model
      :target-id="targetId"
      :title="title"
      header-bg="bg-info"
      confirm-btn="hide"
      cancel-btn="btn-info"
      :modal-lg="true"
      cancel-text="تم"
  >
    <div
        v-if="!!insurances && insurances.length > 0"
        id="user_photos_carousel"
        class="carousel slide"
        data-interval="false"
        data-ride="carousel"
    >
      <div class="carousel-inner">
        <div
            class="carousel-item"
            v-for="(image, index) in insurances"
            :key="image.id"
            :class="{ active: index === 0 }"
        >
          <div v-if="!!image">
            <div class="w-100 d-flex justify-content-center">
              <img class="imageable" v-if="!!image.url" :src="image.url" alt=""/>
            </div>
            <h6
                v-if="!!image.date"
                class="my-1 py-1 bg-soft-danger text-light text-center"
            >
              تم التسليم:&nbsp;{{ image.date }}
            </h6>
          </div>
        </div>
      </div>
      <div v-if="insurances.length > 1">
        <a
            class="carousel-control carousel-control-prev"
            href="#user_photos_carousel"
            data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
            class="carousel-control carousel-control-next"
            href="#user_photos_carousel"
            data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
    <div v-else class="jumbotron py-1 mb-0 w-100">
      لم تقم بتسليم اي وصل تأمين
    </div>
  </confirm-model>
</template>

<script>
import ConfirmModel from "@/components/UI/ConfirmModel";

export default {
  components: {ConfirmModel},
  props: {
    insurances: {required: true},
    targetId: {default: "show_ins_price"},
    title: {default: "صور وصولات التأمين"},
  },
};
</script>

<style scoped>
.imageable {
  max-height: 400px;
  /*width: 100%;*/
}

.carousel-control {
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-control:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
</style>
